<template>
    <div id="markets" class="d-flex-c-ac-jfs pt-20">
        <h2 class="py-20">بازار ها</h2>
        <div class="d-flex-c-ast-jfs-gap10 p-20 l-radius">
            <markets-chart :items="items" :toman="toman"/>
        </div>
    </div>
</template>

<script>
    import MarketsChart from "@/components/Tools/MarketsChart";
    export default {
        name: "Markets",
        components: {MarketsChart},
        data() {
            return {
                toman: true,
                items: [
                    {
                        relatedCoin: 'BITCOIN',
                        price: '1,245,145',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'DOGE_COIN',
                        price: '1,451',
                        change: 2.55,
                    },
                    {
                        relatedCoin: 'ETHEREUM',
                        price: '45,415',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'TETHER',
                        price: '20,145',
                        change: 2.55,
                    },
                    {
                        relatedCoin: 'LITE_COIN',
                        price: '1,245,145',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'CLASSIC_ETHEREUM',
                        price: '1,245,145',
                        change: -2.55,
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    #markets {
        > div {
            background-color: var(--gray-box);
            width: 100%;
            max-width: 1440px;
            table{
                th:nth-of-type(n+2) {
                    background-color: var(--semiDark) !important;
                }
                svg{
                    fill: url(#yellow-gradient);
                }
            }
        }
    }
</style>